/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Paper, Grid, Hidden, Typography, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { toast } from "react-toastify";

import Loader from "../../../components/Loader";
import useStyles from "./styles";

import api from "../../../services/api";

import Task from "./Task";
import FreeTask from "./FreeTask";

function comparar(item, item2) {
  const first = Number(item.task.number);
  const second = Number(item2.task.number);

  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }
  return 0;
}

export default function Tasks() {
  const classes = useStyles();
  const myTeam = useSelector((state) => state.team.team);
  const loading = useSelector((state) => state.team.loading);
  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);

  async function getTasks() {
    setLoadingTasks(true);

    try {
      let response = null;

      if (myTeam) {
        response = await api.get(`tasks/${myTeam.teamId}?type=Jota`);
      } else {
        response = await api.get(`tasks/noTeam?type=Jota`);
      }

      setTasks(response.data.sort(comparar));

      setLoadingTasks(false);
    } catch (error) {
      setLoadingTasks(false);
      toast.error("Ocorreu algum erro ,tente novamente mais tarde.");
    }
  }

  useEffect(() => {
    if (!loading) {
      getTasks();
    }
  }, [loading]);

  function freeTasks() {
    const array = [];

    for (let i = 34; i > tasks.length; i--) {
      array.push(null);
    }

    return array;
  }

  return (
    <Paper
      className={clsx(
        classes.paper,
        (loading || loadingTasks) && classes.loading
      )}
    >
      {loading || loadingTasks ? (
        <Loader size={50} />
      ) : (
        <Grid container spacing={2}>
          <Hidden md mdUp>
            <Grid item xs={12}>
              <Typography className={classes.title}>Desafios Rádio</Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Button onClick={() => getTasks()} className={classes.btn1}>
              Atualizar
            </Button>
          </Grid>

          {tasks.map((task) => (
            <Task data={task} att={() => getTasks()} key={task.task._id} />
          ))}

          {freeTasks().map((task, index) => (
            <FreeTask
              number={index + tasks.length + 1}
              key={`freeTask${index}`}
            />
          ))}
        </Grid>
      )}
    </Paper>
  );
}
