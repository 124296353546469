import React from "react";
import {
  Grid,
  Paper,
  Hidden,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import User from "./User";
import api from "../../../services/api";
import { Pagination } from "@material-ui/lab";
import Modal from "./Modal";

import { saveAs } from "file-saver";
import { toast } from "react-toastify";

export default function Figurines() {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    totalPages: 1,
  });
  const [open, setOpen] = React.useState(false);
  const [has, setHas] = React.useState(null);
  const [data, setData] = React.useState({
    totalData: 0,
    totalChecked: 0,
  });

  async function getUsers(page) {
    try {
      setLoading(true);

      const response = await api.get("/figure/me", {
        page,
        perPage: 8,
      });

      setUsers(response.data.data);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      setData({
        totalData: response.data.totalData,
        totalChecked: response.data.totalChecked,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChange = (event, value) => {
    getUsers(value);
  };

  async function hasInsignea() {
    try {
      const {
        data: { has, photo },
      } = await api.get("/figure/state");

      if (has) {
        setHas(photo);
      }
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde");
    }
  }

  React.useEffect(() => {
    getUsers(1);
    hasInsignea();
  }, []);

  function download(source, fileName) {
    saveAs(source, fileName);
  }

  return (
    <Grid container spacing={5} className={classes.padding}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.title}>
                    Insígnia Digital Remember
                  </Typography>
                </Grid>

                <Hidden sm smDown>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} justify="center">
                      <Grid item>
                        <Button
                          className={classes.btn1}
                          onClick={() => setOpen(true)}
                        >
                          Adicionar contato
                        </Button>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-around"
              >
                <Grid item xs={12}>
                  <Typography className={classes.label}>
                    Bem vindo a Insígnia Digital Remember do JOTA-JOTI 2024
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.value}>
                    Aqui você vai adicionar os contatos que você fez durante o
                    JotaJoti, e após trocar com 50 participantes você conquista
                    a Insígnia Remember! Você deve adicionar o Código do outro
                    participante, e ele também deve adicionar o seu Código para
                    que seja válido. <br /> Para gerar seu Código clique no
                    botão "Gerar Código" nas informações pessoais, o botão fica
                    junto do seu nome na plataforma.
                  </Typography>
                </Grid>

                <Hidden md mdUp>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      className={classes.btn1}
                      onClick={() => setOpen(true)}
                    >
                      Adicionar contato
                    </Button>
                  </Grid>
                </Hidden>

                {!loading && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className={classes.value}>
                        {`Total de contatos: ${data.totalData}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className={classes.value}>
                        {`Total de contatos trocados: ${data.totalChecked}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Typography className={classes.value}>
                        {`contatos pendentes: ${
                          data.totalData - data.totalChecked
                        }`}
                      </Typography>
                    </Grid>

                    {!!has && (
                      <Grid item xs={12}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 20,
                          }}
                        >
                          <Typography className={classes.label}>
                            Parabéns voce conquistou a insígnia Remember!
                          </Typography>

                          <img
                            src={has}
                            style={{ maxWidth: "100%", width: 500 }}
                          />

                          <Button href={has} target="_blank">
                            Download Insígnia
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: 50,
          }}
        >
          <CircularProgress style={{ color: "#363636" }} />
        </div>
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {users.map((item) => (
              <User data={item} att={() => getUsers(1)} />
            ))}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Modal open={open} close={() => setOpen(false)} att={() => getUsers(1)} />
    </Grid>
  );
}
