import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useChatWootWithAuth = () => {
  const me = useSelector((state) => state?.user?.me);
  const team = useSelector((state) => state?.team?.team);

  function chatWootConfig() {
    if (!me) {
      return;
    }

    window.$chatwoot.setUser(me._id, {
      email: me.email,
      email_valido: me.email,
      name: me.name,
      registro: me.register,
      regiao: me.region,
      uel: me.groupName,
      equipe: team?.name,
    });

    window.$chatwoot.setCustomAttributes({
      registro: me.register,
      regiao: me.region,
      uel: me.groupName,
      email_plataforma: me.email,
      email: me.email,
      email_valido: me.email,
      equipe: team?.name,
    });
  }

  useEffect(() => {
    if (!me) {
      return;
    }

    window.addEventListener("chatwoot:ready", chatWootConfig);

    return () => {
      window.removeEventListener("keydown", chatWootConfig);
    };
  }, [chatWootConfig, me]);
};
