import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SwipeableDrawer,
  List,
  ListItem,
  Typography,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemIcon,
} from "@material-ui/core";
import history from "../../../../services/history";
import useStyles from "./styles";
import { signOut } from "../../../../store/modules/auth/actions";

import out from "../../../../assets/icons/out.svg";
import organizationBlack from "../../../../assets/icons/organizationBlack.svg";
import organization from "../../../../assets/icons/organization.svg";
import teamBlack from "../../../../assets/icons/teamBlack.svg";
import teamIcon from "../../../../assets/icons/team.svg";
import taskBlack from "../../../../assets/icons/taskBlack.svg";
import task from "../../../../assets/icons/task.svg";

import figuresBlack from "../../../../assets/icons/figuresBlack.svg";
import figures from "../../../../assets/icons/figures.svg";

import points from "../../../../assets/icons/points.svg";
import pointsBlack from "../../../../assets/icons/pointsBlack.svg";

import Gravatar from "react-gravatar";

import Modal from "../../../../components/Modal";
import Header from "../Header";
import { toast } from "react-toastify";

export default function MobileSidebar({ open, trogle }) {
  const me = useSelector((state) => state.user.me);
  const team = useSelector((state) => state.team.team);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  function selected(url) {
    return window.location.href.indexOf(url) !== -1;
  }

  async function handleOut() {
    dispatch(signOut());
  }

  const openTasks = () => {
    if (!team) {
      return toast.error(
        "Você deve ter uma equipe para visualizar as tarefas!"
      );
    }

    history.push("/desafios-joti");
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => trogle()}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Header open={open} trogle={() => trogle()} />
      <List>
        <ListItem className={classes.profile}>
          <ListItemAvatar>
            <Gravatar email={me?.email} className={classes.avatar} size={150} />
          </ListItemAvatar>
          <ListItemText>
            <Typography className={classes.name} noWrap>
              {me.name}
            </Typography>
            {me.team && me.team.level && (
              <Typography className={classes.level}>{me.team.level}</Typography>
            )}
          </ListItemText>
        </ListItem>
      </List>
      <List style={{ flex: 1 }}>
        <ListItem
          button
          onClick={() => {
            history.push("dashboard");
            trogle();
          }}
        >
          <ListItemIcon>
            <img src={selected("dashboard") ? teamIcon : teamBlack} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={
                selected("dashboard") ? classes.selected : classes.normal
              }
            >
              Minha Equipe
            </Typography>
          </ListItemText>
        </ListItem>

        {(!team || team?.type !== "Jota") && (
          <ListItem
            button
            onClick={() => {
              openTasks();
              trogle();
            }}
          >
            <ListItemIcon>
              <img src={selected("desafios-joti") ? task : taskBlack} />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  selected("desafios-joti") ? classes.selected : classes.normal
                }
              >
                Desafios
              </Typography>
            </ListItemText>
          </ListItem>
        )}

        <ListItem
          button
          onClick={() => {
            history.push("desafios-jota");
            trogle();
          }}
        >
          <ListItemIcon>
            <img src={selected("desafios-jota") ? task : taskBlack} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={
                selected("desafios-jota") ? classes.selected : classes.normal
              }
            >
              Desafios Rádio
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/teams");
            trogle();
          }}
        >
          <ListItemIcon>
            <img src={selected("teams") ? teamIcon : teamBlack} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={selected("teams") ? classes.selected : classes.normal}
            >
              Equipes
            </Typography>
          </ListItemText>
        </ListItem>
        {/*  <ListItem
          button
          onClick={() => {
            history.push("/certificate");
            trogle();
          }}
        >
          <ListItemIcon>
            <img
              src={selected("certificate") ? points : pointsBlack}
              style={{ width: 35 }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={
                selected("certificate") ? classes.selected : classes.normal
              }
            >
              Certificado
            </Typography>
          </ListItemText>
        </ListItem> */}

        {/* 
        <ListItem
          button
          onClick={() => {
            history.push("organization");
            trogle();
          }}
        >
          <ListItemIcon>
            <img
              src={selected("organization") ? organization : organizationBlack}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={
                selected("organization") ? classes.selected : classes.normal
              }
            >
              Organização JOTI
            </Typography>
          </ListItemText>
        </ListItem>
 */}
        {/*  <ListItem
          button
          onClick={() => {
            history.push("insignia-remember");
            trogle();
          }}
        >
          <ListItemIcon>
            <img
              style={{ height: 20, width: 20 }}
              src={selected("insignia-remember") ? figures : figuresBlack}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={
                selected("insignia-remember")
                  ? classes.selected
                  : classes.normal
              }
            >
              Insígnia Remember
            </Typography>
          </ListItemText>
        </ListItem> */}
        {/*
        <ListItem
          button
          onClick={() => {
            history.push("points");
            trogle();
          }}
        >
          <ListItemIcon>
            <img src={selected("points") ? organization : organizationBlack} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              className={selected("points") ? classes.selected : classes.normal}
            >
              Pontuação
            </Typography>
          </ListItemText>
        </ListItem>
        */}

        <ListItem button onClick={() => setOpenModal(true)}>
          <ListItemIcon>
            <img src={out} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.normal}>Sair</Typography>
          </ListItemText>
        </ListItem>
      </List>

      <ListItem>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <a
              className={classes.sup}
              href="https://www.escoteiros.org.br/jota-joti/"
              target="_blank"
            >
              Boletim
            </a>

            {/*   <a
              className={classes.sup}
              href="https://suporte.jotajoti.com.br/"
              target="_blank"
            >
              Suporte
            </a> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <a
              className={classes.sup}
              href="https://discord.gg/HBtswBrpa7"
              target="_blank"
            >
              Discord
            </a>
            <a
              className={classes.sup}
              href="https://stats.uptimerobot.com/RL6R9uGEw9"
              target="_blank"
            >
              Status
            </a>
          </div>
        </div>
      </ListItem>

      <ListItem>
        <Typography className={classes.copyright}>
          Copyright ©JOTA-JOTI 2024
        </Typography>
      </ListItem>

      <Modal
        open={openModal}
        close={() => setOpenModal(false)}
        handle={() => handleOut()}
        title="Encerrar sessão"
        text="Tem certeza que deseja sair da Plataforma JOTA-JOTI?"
        bt1Text="Sair"
        bt2Text="Cancelar"
      />
    </SwipeableDrawer>
  );
}
