import React from "react";

import { Paper, Grid, Typography, Button, Hidden } from "@material-ui/core";

import useStyles from "./styles";
import Card from "./Card";

import alecs from "../../../assets/Team/alecs.jpeg";
import brasil from "../../../assets/Team/brasil.jpg";
import antonio from "../../../assets/Team/antonio.jpeg";
import caio from "../../../assets/Team/caio.jpeg";
import freitas from "../../../assets/Team/freitas.jpeg";
import roberto from "../../../assets/Team/roberto.jpeg";
import luis from "../../../assets/Team/luis.jpeg";
import ana from "../../../assets/Team/ana.jpeg";
import zibetti from "../../../assets/Team/zibetti.jpeg";

// import { Container } from './styles';

const users = [
  {
    name: "Alecsander Souza Farias",
    func: "Equipe técnica",
    picture: alecs,
  },
  {
    name: "Fernando Brasil",
    func: "Equipe técnica",
    picture: brasil,
  },
  {
    name: "Caio",
    func: "Comunicação",
    picture: caio,
  },
  {
    name: "Rafael de Freitas",
    func: "Correção",
    picture: freitas,
  },
  {
    name: "Antônio Cosso",
    func: "Coordenador Geral Adjunto",
    picture: antonio,
  },
  {
    name: "Roberto Basso",
    func: "Coordenação Geral",
    picture: roberto,
  },
  {
    name: "Luis Gustavo",
    func: "Suporte",
    picture: luis,
  },
  {
    name: "Ana",
    func: "Mobilização",
    picture: ana,
  },
  {
    name: "Rafael Zibetti",
    func: "Programa",
    picture: zibetti,
  },
];

export default function Organization() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.card}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                Organização JOTA-JOTI 2024
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.text}>
                Conheça a equipe por trás da organização do JOTA-JOTI 2024.
              </Typography>
            </Grid>

            <Hidden smDown>
              <Grid item xs={12}>
                <Typography className={classes.text}>
                  Sempre alerta !
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

        {users.map((user) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card data={user} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
