import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Paper,
  Button,
  Typography,
  Grid,
  Hidden,
  CircularProgress,
  Link,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import clsx from "clsx";
import farb from "../../../assets/icons/horizontal-invertida.png";

import { Form } from "@unform/web";
import * as Yup from "yup";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import logo from "../../../assets/logo2023Color.svg";
import useStyles from "../SignIn/styles";
import useStyles2 from "./styles";
import dateMask from "../../../components/maskInput/date";
import history from "../../../services/history";
import BrValidator from "./validators/br";
import OutValidator from "./validators/out";
import { toast } from "react-toastify";

import { countries } from "../../../utils/countries";
import { associations } from "../../../utils/associations";

import { signUpRequest } from "../../../store/modules/auth/actions";
import { withStyles } from "@material-ui/core/styles";

const CustomCheckBox = withStyles({
  root: {
    color: "#664ACF",
    "&$checked": {
      color: "#664ACF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function SignUp() {
  const dispatch = useDispatch();
  const [type, setType] = useState(1);
  const classes = useStyles();
  const classes2 = useStyles2();
  const formRef = useRef();
  const loading = useSelector((state) => state.auth.loading);

  const [register, setRegister] = useState("");
  const [dataOut, setDataOut] = useState({
    name: "",
    email: "",
    date: "",
    country: "BR",
    association: "Externo",
  });

  async function handleSubmit() {
    formRef.current.setErrors({});

    try {
      if (type === 1) {
        await BrValidator({ register });

        dispatch(signUpRequest({ register }));
      } else {
        await OutValidator(dataOut);

        dispatch(signUpRequest(dataOut));
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleChange(e, name) {
    setDataOut({
      ...dataOut,
      [name]: e.target.value,
    });
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <Grid Container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img className={classes.logo} src={logo} />
              </div>
            </Grid>

            <Grid item xs={12} className={classes.descriptionDiv}>
              <Typography className={classes.description}>
                Bem-vindo(a) ao JOTA-JOTI Challenge 2024!
              </Typography>
              <Hidden smDown>
                <Typography className={classes.description}>
                  Preencha os campos para se cadastrar na plataforma.
                </Typography>
              </Hidden>
            </Grid>

            <Grid item xs={12} justify="center" style={{ marginBottom: 30 }}>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setType(1)}
                    style={{ borderRadius: "3px 0px 0px 3px" }}
                    className={clsx(
                      classes2.button,
                      type === 1 && classes2.selected
                    )}
                  >
                    Associado(A) Brasileiro(A)
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setType(2)}
                    style={{ borderRadius: "0px 3px 3px 0px" }}
                    className={clsx(
                      classes2.button,
                      type === 2 && classes2.selected
                    )}
                  >
                    Não associado(a) ou Estrangeiro(a)
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                {type === 1 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="register"
                        label="Registro (Sem digito e sem zero à esquerda)"
                        value={register}
                        onChange={(e) => setRegister(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        name="name"
                        label="Nome"
                        value={dataOut.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Input
                        type="email"
                        name="email"
                        label="E-mail"
                        value={dataOut.email}
                        onChange={(e) => handleChange(e, "email")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Input
                        name="date"
                        label="Data de nascimento"
                        value={dataOut.date}
                        onChange={(e) => handleChange(e, "date")}
                        InputProps={{
                          inputComponent: dateMask,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Select
                        name="country"
                        label="País"
                        options={countries.map((c) => ({
                          value: c.code,
                          label: `${c.name} - (${c.code})`,
                        }))}
                        value={dataOut.country}
                        onChange={(e) => handleChange(e, "country")}
                        /*   InputProps={{
                          inputComponent: dateMask,
                        }}  */
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Select
                        name="association"
                        label="Associação"
                        options={associations.map((c) => ({
                          value: c,
                          label: c,
                        }))}
                        value={dataOut.association}
                        onChange={(e) => handleChange(e, "association")}
                      />
                    </Grid>
                  </Grid>
                )}

                {loading ? (
                  <div className={classes.loading}>
                    <CircularProgress style={{ color: "#744EAA" }} size={40} />
                  </div>
                ) : (
                  <>
                    <Button fullWidth className={classes.btn1} type="submit">
                      Registrar
                    </Button>
                    <Button
                      className={classes.btn2}
                      fullWidth
                      onClick={() => history.push("/")}
                    >
                      Ja tenho conta!
                    </Button>
                  </>
                )}
              </Form>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Link
              className={classes.copyright2}
              color="inherit"
              href="https://devbrasil.com.br"
              target="_blank"
            >
              Desenvolvido por
              <img src={farb} style={{ maxWidth: 75, margin: "0px 5px" }} /> ,
              {new Date().getFullYear()}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
