import axios from "axios";

const api = axios.create({
  //baseURL: 'http://localhost:4000',
  //baseURL: "https://1898-189-58-98-206.ngrok-free.app",
  baseURL: "https://api.jotajoti.com.br",
  /* baseURL: "https://89ab-2804-7f4-8280-4786-c913-9292-8422-2bbf.sa.ngrok.io/", */
});

export default api;
