import React from "react";
import { useDispatch } from "react-redux";
import { getMeRequest } from "../../../../../store/modules/user/actions";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import useStyles from "./styles";
import { Form } from "@unform/web";
import Input from "../../../../../components/Input";
import * as Yup from "yup";
import validator from "./validator";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import clsx from "clsx";

const CustomRadio = withStyles({
  root: {
    color: "#664ACF",
    "&$checked": {
      color: "#664ACF",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CreateTeam = ({ open, close }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = React.useRef();
  const [teamCode, setTeamCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("Lobinho");

  async function handle(data) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator(data);

      await api.post("/team/create", {
        ...data,
        type,
      });

      dispatch(getMeRequest());

      close();

      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(
          err?.response?.data?.error ||
            "Ocorreu algum erro,tente novamente mais tarde"
        );
      }

      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" scroll="body">
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Criar equipe</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>
            Digite as informaçōes da sua equipe e chame os participantes!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={handle}>
            <Input name="teamName" label="Nome" style={{ marginBottom: 20 }} />

            <Input
              name="description"
              label="Descrição"
              style={{ marginBottom: 20 }}
            />

            <Typography className={classes.text}>Tipo da equipe</Typography>

            <RadioGroup
              aria-label="type"
              name="type"
              value={type}
              onChange={(event) => setType(event.target.value)}
            >
              
              <FormControlLabel
                value="Lobinho"
                control={<CustomRadio />}
                label="Lobinho"
              />
                <FormControlLabel
                value="Escoteiro"
                control={<CustomRadio />}
                label="Escoteiro"
              />
                <FormControlLabel
                value="Senior"
                control={<CustomRadio />}
                label="Senior"
              />
                <FormControlLabel
                value="Pioneiro"
                control={<CustomRadio />}
                label="Pioneiro"
              />
            </RadioGroup>
          </Form>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => formRef.current.submitForm()}
            fullWidth
            className={classes.button1}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.circular} size={30} />
              </div>
            ) : (
              "Criar Equipe"
            )}
          </Button>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 100 }}>
          <Button
            onClick={() => close()}
            fullWidth
            className={clsx(classes.button1, classes.red)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CreateTeam;
