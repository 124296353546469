import { useEffect } from "react";

const ChatwootIntegration = () => {
  useEffect(() => {
    window.chatwootSettings = {
      position: "right",
      type: "expanded_bubble",
      launcherTitle: "Suporte",
    };

    (function (d, t) {
      var BASE_URL = "https://atendimento.jotajoti.com.br/";
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: "uj1ZXA11MGwe9pq39broGa1m",
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);

  return null; // This component doesn't render anything visually
};

export default ChatwootIntegration;
